import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import FeaturedArticles from 'components/FeaturedArticles';
import ProductListFilter from 'components/ProductListFilter';

import NFBodyRenderer, { IExpectedStructures } from 'components/common/NFBodyRenderer';
import RecentlyViewedProducts from 'components/RecentlyViewedProducts/RecentlyViewedProducts';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';
import AdditionalMessage from 'components/AdditionalMessage';

import { IUmbracoProductListIncomeData, IUmbracoProductListParsedData } from './models';
import parseUmbracoProductListIncomeData from './parsers';

import './productListingPage.scss';
import './productListingPageOverride.scss';

interface IPropsProductListPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; productsLinks: string[] };
  data: IUmbracoProductListIncomeData;
}

const ExpectedStructures: IExpectedStructures = {
  'Featured Articles': {
    Component: FeaturedArticles,
    dataKey: 'relatedArticles',
  },
  'Recently viewed products': {
    Component: RecentlyViewedProducts,
    dataKey: 'recentlyViewedProducts',
  },
  'Featured Articles From Symptoms & Pain Advice Sections': {
    Component: ExtendedFeaturedArticles,
    dataKey: 'extendedFeaturedArticles',
  },
};

const ProductListPage: FC<IPropsProductListPage> = (props) => {
  const { breadcrumbs, productsLinks } = props.pageContext;
  const parsedData: IUmbracoProductListParsedData = parseUmbracoProductListIncomeData({
    ...props.data,
    breadCrumbs: breadcrumbs,
    productsLinks,
  });

  const {
    umbracoProductList: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          seoContentType,
          lang,
          pageName,
        },
      ],
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = props.data;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoContentType,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      className="products-listing-page"
    >
      {parsedData.banner ? <Banner {...parsedData.banner} /> : null}
      {parsedData.filterProductList ? (
        <div>
          <ProductListFilter {...parsedData.filterProductList} listingName={pageName} />
        </div>
      ) : null}
      <NFBodyRenderer
        bodyStructure={props.data.umbracoProductList.nodes[0].body}
        expectedStructures={ExpectedStructures}
        parsedData={parsedData}
        additionalBodyProps={{ pageName }}
      />
      {parsedData?.additionalMessageData?.additionalMessage ? (
        <AdditionalMessage text={parsedData.additionalMessageData?.additionalMessage} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String = ""
    $articleTagId: Int
    $productsLinks: [String]
    $pageBannerImageSize: Int = 600
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    ...FragmentExtendedFeaturedArticlesData
    umbracoProductList: allUmbracoProductList(
      filter: { link: { eq: $link }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentSeo
        body {
          properties {
            ...FragmentBanner
            ...FragmentFeaturedArticles
            ...FragmentFilterProductList
            ...FragmentRecentlyViewedProducts
            ...FragmentExtendedFeaturedArticles
          }
          structure
        }
        umbracoId
        lang
        link
        showManuallySelectedProducts
        isProductListingPartOfKidsHub
        pageName
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    featuredArticlesByTag: allUmbracoArticles(
      filter: { tags: { elemMatch: { id: { eq: $articleTagId } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    defaultProducts: allUmbracoProduct(
      filter: { link: { in: $productsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    productFilters(lang: { eq: $lang }) {
      ...FragmentProductsFilters
    }
    productListConfigs(lang: { eq: $lang }) {
      ...FragmentProductListConfigs
    }
    pdpSettings(lang: { eq: $lang }) {
      recentlyViewedTitle
    }
    pageLangs: allUmbracoProductList(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default ProductListPage;
